import React from 'react'
import {Col} from 'react-bootstrap'
import Img from 'gatsby-image'
import { OutboundLink } from "gatsby-plugin-google-gtag"

const Logo = ({ url, logo, altText }) => (
  <Col
    xs={12}
    sm={6}
    lg={4}
    style={{
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      padding: "50px",
    }}
  >
    <OutboundLink href={url} target="_blank" rel="noopener noreferrer">
      <Img fixed={logo} alt={altText} />
    </OutboundLink>
  </Col>
)

export default Logo