import React from 'react'
import {Col} from 'react-bootstrap'
import Img from 'gatsby-image'

const IconFeature = ({ icon, text }) => (
  <Col xs={6} lg={3} style={{ padding: "20px" }}>
    <div style={{ padding: "10px" }} className="icon">
      <Img height="auto" width="150" fixed={icon} alt={text} />
    </div>
    <p className="text">{text}</p>
  </Col>
)

export default IconFeature