import React from 'react'

import styles from './SkewHeader.module.css'

const SkewHeader = ({ headerContent, reference }) => (
  <div className={styles.header} ref={reference}>
    <h2 className={styles.content}>{headerContent}</h2>
  </div>
)

export default SkewHeader