import {createRef} from 'react'
import { clickEvent, getLocation } from './events'

export function createScrollingRef(eventCategory, eventAction) {
  const ref = createRef()
  const scrollToRef = () => {
    clickEvent(eventCategory, eventAction, getLocation())
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
  }
  return [ref, scrollToRef]
}

