import React from 'react'
import Img from 'gatsby-image'

import { Row, Col } from "react-bootstrap"
import styles from './Section.module.css'

export const SectionLeft = ({ image, children }) => (
          <Row className={styles.sectionRow}>
            <Col className={styles.sectionImageDiv} lg={5}>
              <Img fluid={image} className={styles.sectionImage} alt="" />
            </Col>
            <Col className={styles.sectionContent} lg={7}>
              {children}
            </Col>
          </Row>
       )

export const SectionRight = ({ image, children }) => (
          <Row className={styles.sectionRow}>
            <Col
              className={styles.sectionContent}
              xs={{ span: 12, order: 1 }}
              lg={{ span: 7, order: 0 }}
            >
              {children}
            </Col>
            <Col className={styles.sectionImageDiv} lg={5}>
              <Img fluid={image} className={styles.sectionImage} alt="" />
            </Col>
          </Row>
       )

export default SectionLeft