import React from "react"
import { Button, Form, Col, Row, Container } from "react-bootstrap"
import { clickEvent, conversionEvent, getLocation } from "../../utils/events"

function ProductContactForm({ formName, formPrompt, checkBoxes }) {
  return (
    <Container style={{ maxWidth: "550px" }}>
      <p style={{ textAlign: "center" }}>{formPrompt}</p>
      <Form
        action="/contact-success/"
        name={formName}
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="bot-field" />
        <Form.Group as={Row}>
          <Col sm="2" xs="0">
            <Form.Label>Name*</Form.Label>
          </Col>
          <Col sm={5}>
            <Form.Control
              type="text"
              id="first_name"
              placeholder="First"
              name="first_name"
              required
            />
          </Col>
          <Col sm={5}>
            <Form.Control
              type="text"
              id="last_name"
              placeholder="Last"
              name="last_name"
              required
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm="2" xs="0">
            <Form.Label>Email*</Form.Label>
          </Col>
          <Col sm="10">
            <Form.Control
              type="email"
              id="email"
              placeholder="Enter Email Address"
              name="email"
              required
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col xs="12">
            <Form.Label style={{ display: "block" }}>
              What are you interested in?
            </Form.Label>
          </Col>
          <Col sm="2" xs="0"></Col>
          <Col sm="10">
            {checkBoxes.map((checkBox, index) => (
              <Form.Check
                key={index}
                label={checkBox.label}
                type="checkbox"
                id={checkBox.id}
                name={checkBox.id}
              />
            ))}
          </Col>
        </Form.Group>
        <Form.Group>
          <Form.Label>Anything else?</Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            id="message"
            name="message"
            placeholder="Details and questions about what you are planning to use it for are helpful!"
          />
        </Form.Group>
        <Form.Group style={{ textAlign: "left" }}>
          <Form.Label style={{ display: "block" }}>
            We have a quarterly(ish) newsletter with industry & protocol news,
            commentary and product updates, would you like us to add you?
          </Form.Label>
          <Form.Check
            inline
            label="Yes, add me"
            type="checkbox"
            id="signupNewsletter"
            name="signupNewsletter"
          />
        </Form.Group>

        <Row style={{ justifyContent: "center" }}>
          <Button
            className="btn-gridfabric"
            type="submit"
            size="lg"
            onClick={() => {
              clickEvent("ProductContact", "Submit", getLocation())
              conversionEvent("AW-690244398/n3m0CNj8xtABEK6WkckC")
            }}
          >
            Submit
          </Button>
        </Row>
      </Form>
    </Container>
  )
}

export default ProductContactForm
