import React, { useState } from "react"
import { Col, Collapse } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretRight, faCaretUp } from "@fortawesome/free-solid-svg-icons"
import { clickEvent } from "../../utils/events"

import styles from "./ValueFeature.module.css"

const ValueFeature = ({ icon, title, subtitle, children }) => {
  const [open, setOpen] = useState(false)

  return (
    <Col md={6} lg={4} className={styles.container}>
      <div className={styles.iconWrapper}>
        <img src={icon} alt="" />
      </div>
      <h6 className={styles.title}>{title}</h6>
      <p className={styles.subtitle}>
        {subtitle} <br />
        {!open ? (
          <button
            onClick={() => {
              clickEvent("Plaid Page", "Expand Section", title)
              setOpen(!open)
            }}
            aria-controls="see more"
            aria-expanded={open}
            className={styles.button}
            id={"more" + title}
          >
            More <FontAwesomeIcon icon={faCaretRight} />
          </button>
        ) : null}
        {open ? (
          <button
            onClick={() => setOpen(!open)}
            aria-controls="see less"
            aria-expanded={open}
            className={styles.button}
            id={"less" + title}
          >
            Less <FontAwesomeIcon icon={faCaretUp} />
          </button>
        ) : null}
      </p>
      <Collapse in={open}>
        <p className={styles.moreText}>{children}</p>
      </Collapse>
    </Col>
  )
}

export default ValueFeature
