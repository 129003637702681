import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import ProductHero from "../components/ProductHero/ProductHero"
import IconFeature from "../components/IconFeature/IconFeature"
import ValueFeature from "../components/ValueFeature/ValueFeature"
import { SectionLeft } from "../components/Section/Section"
import SkewHeader from "../components/SkewHeader/SkewHeader"
import Logo from "../components/Logo/Logo"
import ProductContactForm from "../components/ProductContactForm/ProductContactForm"
import { createScrollingRef } from "../utils/scrollHooks"
import { clickEvent } from "../utils/events"
// import BraeburnCaseStudy from "../data/files/Braeburn-Plaid-case-study.pdf"

const PlaidPage = props => {
  const [getPlaid, scrollToGetPlaid] = createScrollingRef(
    "Plaid Page",
    "Get Plaid Scroll"
  )

  return (
    <Layout>
      <SEO title="Plaid" />
      <ProductHero
        image={props.data.heroImage.image.fluid}
        title="Plaid"
        paragraph1="Plaid is a licensed software solution that allows companies to quickly implement OpenADR and translate it into their existing APIs. Companies that implement Plaid get a certified OpenADR 2.0b Virtual End Node (VEN) as though they built it from scratch."
        paragraph2="Demo hosted version, for free, with no software download or dependencies required."
        demo="Demo plaid"
        demoLink="https://plaid.gridfabric.io/registration"
        cta="Contact us"
        ctaClick={scrollToGetPlaid}
        openadrBadge={props.data.openadrBadge.image.fixed}
      ></ProductHero>
      <div style={{ backgroundColor: "#F1F2F5" }}>
        <Container style={{ padding: "100px 5px", textAlign: "center" }}>
          <h2 className="section-header">
            Any internet connected product that uses energy can participate in
            load shifting
          </h2>
          <Row>
            <IconFeature
              icon={props.data.tstatIcon.image.fixed}
              text="Thermostat / HVAC manufacturers"
            />
            <IconFeature
              icon={props.data.whIcon.image.fixed}
              text="Water Heating"
            />
            <IconFeature
              icon={props.data.lightingIcon.image.fixed}
              text="Lighting Controls"
            />
            <IconFeature
              icon={props.data.solarIcon.image.fixed}
              text="Solar, Storage &#38; Inverters"
            />
            <IconFeature
              icon={props.data.evIcon.image.fixed}
              text="EV Charging"
            />
            <IconFeature
              icon={props.data.bmsIcon.image.fixed}
              text="Building Management Systems"
            />
            <IconFeature
              icon={props.data.industrialIcon.image.fixed}
              text="Industrial Controls"
            />
            <IconFeature
              icon={props.data.drAggIcon.image.fixed}
              text="Demand Response Aggregator"
            />
          </Row>
        </Container>
      </div>
      <Container style={{ padding: "100px 5px" }}>
        <h2 style={{ textAlign: "center", padding: "50px" }}>
          OpenADR certification adds value to your products
        </h2>
        <Row>
          <ValueFeature
            icon={props.data.regulatoryIcon.image.file.url}
            title="Fulfill regulatory requirement"
            subtitle="It may be required where you operate."
          >
            California code Title 24 requires certain appliances in new
            buildings to be OpenADR compliant. In turn, product manufacturers
            will need to be OpenADR compliant to continue serving customers in
            California.{" "}
            <Link
              to="/blog/california-title-24-embraces-cloud-based-vens"
              onClick={() => clickEvent("Plaid Page", "Title 24 Blog")}
            >
              Learn more in this blog post
            </Link>
            .
          </ValueFeature>
          <ValueFeature
            icon={props.data.increaseSalesIcon.image.file.url}
            title="Increase product sales"
            subtitle="Add valuable features and unlock new sales channels."
          >
            Utilities incentivize the purchase of devices that can participate
            in their demand response rates, unlocking more sales. Savvy
            customers know that they can earn money by participating in demand
            response and seek products that help them do so.
          </ValueFeature>
          <ValueFeature
            icon={props.data.revenueStreamsIcon.image.file.url}
            title="Add new revenue streams"
            subtitle="A source of high margin, repeatable revenue streams."
          >
            Product manufacturers that can offer load shifting capacity to
            aggregators and load serving entities get paid for that service.
            Some product manufacturers expect to make more money from providing
            these services than from selling the devices. GridFabric can help
            you tap into these programs across the country.
          </ValueFeature>
        </Row>
        <hr style={{ margin: "100px 0" }} />
        <Row>
          <Col>
            <h2 className="section-header">
              Plaid is the fastest way for product companies to get OpenADR
              certified
            </h2>
            <SectionLeft image={props.data.certified.image.fluid}>
              <h5>
                Implement OpenADR, the leading load shifting protocol, and get
                certified in days, not months.
              </h5>
              <ul styles={{ listStyle: "none" }}>
                <li>No risk, guaranteed OpenADR compliance for your product</li>
                <li>Flexible implementation on device or in the cloud</li>
                <li>Save your software development team months of work</li>
              </ul>
            </SectionLeft>
          </Col>
        </Row>{" "}
        <hr style={{ margin: "100px 0" }} />
        <Container>
          <div style={{ margin: "40px 0 160px 0" }}>
            <h2 className="section-header">Try it out for free</h2>
            <div style={{ maxWidth: "700px", margin: "10px auto 40px auto" }}>
              <Img
                fluid={props.data.plaidDemoScreenshot.image.fluid}
                alt="Hero"
              />
            </div>
            <div style={{ maxWidth: "700px", margin: "auto" }}>
              <p>
                The Plaid web demo lets you get a feel for using the OpenADR
                protocol and how Plaid implements it without any requirement to
                host or run software yourself. You also have access to a VTN
                where you can create events that will propagate through Plaid.
              </p>
              <div style={{ textAlign: "center" }}>
                <a
                  href="https://plaid.gridfabric.io/registration"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Button className="btn-gridfabric btn-lg">
                    Try it now
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </Container>
        <hr style={{ margin: "100px 0" }} />
        <Row style={{ textAlign: "center" }}>
          <h2 className="section-header">Successfully implemented by</h2>
        </Row>
        <Row className="justify-content-center">
          <Logo
            url="https://www.stem.com/"
            logo={props.data.stemLogo.image.fixed}
            altText="Stem"
          />
          <Logo
            url="https://products.openadr.org/product/lennox-international-inc-icomfort-s30-model-number-104329-xx/"
            logo={props.data.lennoxLogo.image.fixed}
            altText="Lennox International"
          />
          <Logo
            url="https://products.openadr.org/product/braeburn-systems-llc-bluelink-smart-connect/"
            logo={props.data.braeburnLogo.image.fixed}
            altText="Braeburn Systems"
          />
          <Logo
            url="https://products.openadr.org/product/e-gear-llc-e-gear-nova-server/"
            logo={props.data.eGearLogo.image.fixed}
            altText="E-Gear"
          />
          <Logo
            url="https://products.openadr.org/product/packetized-energy-nimble/"
            logo={props.data.packetizedEnergyLogo.image.fixed}
            altText="Packetized Energy"
          />
          <Logo
            url="https://www.shiftedenergy.com/"
            logo={props.data.shiftedEnergyLogo.image.fixed}
            altText="Shifted Energy"
          />
        </Row>
        {/* <Row style={{ textAlign: "center" }}>
          <p style={{ width: "80%", margin: "auto" }}>
            <a href={BraeburnCaseStudy} download="Braeburn Case Study">
              Download our case study
            </a>
            &nbsp; to learn more about how Braeburn used Plaid to become OpenADR
            compliant in response to regulatory requirements
          </p>
        </Row> */}
      </Container>
      <div className="breaker-100"></div>
      <SkewHeader headerContent="Get Plaid" reference={getPlaid} />
      <div className="breaker-100"></div>
      <Container>
        <Row style={{ maxWidth: "700px", margin: "auto", minHeight: "500px" }}>
          <ProductContactForm
            formName="plaid-contact-form"
            formPrompt="Get in touch about Plaid"
            checkBoxes={[
              { label: "Fulfilling regulatory requirement", id: "regulatory" },
              {
                label: "Load shifting program participating",
                id: "loadShifting",
              },
              { label: "Utility sales channels", id: "utilitySales" },
              { label: "Other", id: "other" },
            ]}
          />
        </Row>
      </Container>
    </Layout>
  )
}

export default PlaidPage

export const iconImage = graphql`
  fragment iconImage on ContentfulWebsiteImages {
    image {
      fixed(width: 120) {
        ...GatsbyContentfulFixed_withWebp
      }
    }
  }
`

export const customerLogo = graphql`
  fragment customerLogo on ContentfulWebsiteImages {
    image {
      fixed(width: 200) {
        ...GatsbyContentfulFixed_withWebp
      }
    }
  }
`

export const plaidPageQuery = graphql`
  query {
    certified: contentfulWebsiteImages(imageId: { eq: "certified" }) {
      ...standardImage
    }
    openadrBadge: contentfulWebsiteImages(imageId: { eq: "openadr20bLogo" }) {
      image {
        fixed(width: 200) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
    }
    tstatIcon: contentfulWebsiteImages(imageId: { eq: "tstatIcon" }) {
      ...iconImage
    }
    lightingIcon: contentfulWebsiteImages(imageId: { eq: "lightingIcon" }) {
      ...iconImage
    }
    whIcon: contentfulWebsiteImages(imageId: { eq: "whIcon" }) {
      ...iconImage
    }
    industrialIcon: contentfulWebsiteImages(imageId: { eq: "industrialIcon" }) {
      ...iconImage
    }
    solarIcon: contentfulWebsiteImages(imageId: { eq: "solarIcon" }) {
      ...iconImage
    }
    evIcon: contentfulWebsiteImages(imageId: { eq: "evIcon" }) {
      ...iconImage
    }
    bmsIcon: contentfulWebsiteImages(imageId: { eq: "bmsIcon" }) {
      ...iconImage
    }
    drAggIcon: contentfulWebsiteImages(imageId: { eq: "aggIcon" }) {
      ...iconImage
    }
    stemLogo: contentfulWebsiteImages(imageId: { eq: "stemLogo" }) {
      ...customerLogo
    }
    braeburnLogo: contentfulWebsiteImages(imageId: { eq: "braeburnLogo" }) {
      ...customerLogo
    }
    eGearLogo: contentfulWebsiteImages(imageId: { eq: "egearLogo" }) {
      ...customerLogo
    }
    shiftedEnergyLogo: contentfulWebsiteImages(imageId: { eq: "shiftedLogo" }) {
      ...customerLogo
    }
    packetizedEnergyLogo: contentfulWebsiteImages(
      imageId: { eq: "packetizedLogo" }
    ) {
      ...customerLogo
    }
    lennoxLogo: contentfulWebsiteImages(imageId: { eq: "lennoxLogo" }) {
      ...customerLogo
    }
    heroImage: contentfulWebsiteImages(imageId: { eq: "plaidHero" }) {
      ...standardImage
    }
    plaidDemoScreenshot: contentfulWebsiteImages(
      imageId: { eq: "plaidDemoScreenshot" }
    ) {
      ...standardImage
    }
    regulatoryIcon: contentfulWebsiteImages(imageId: { eq: "regIconSVG" }) {
      image {
        file {
          url
        }
      }
    }
    increaseSalesIcon: contentfulWebsiteImages(
      imageId: { eq: "productSalesSVG" }
    ) {
      image {
        file {
          url
        }
      }
    }
    revenueStreamsIcon: contentfulWebsiteImages(imageId: { eq: "newRevSVG" }) {
      image {
        file {
          url
        }
      }
    }
  }
`
//Note that sectionImage is defined in the index.js file
